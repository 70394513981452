export const CONSOLIDATION_DEFINITIONS = {
  deuev: { icon: 'connect_without_contact', captionPlural: 'DEÜV' },
  a1Antrag: { icon: 'globe', captionPlural: 'A1 Anträge' },
  arbeitsbescheinigung: {
    icon: 'article',
    captionPlural: 'Arbeitsbescheinigungen',
  },
  entgeltersatzleistung: {
    icon: 'price_change',
    captionPlural: 'Entgeltersatzleistungen',
  },
  vortragswerteKrankenkasse: {
    icon: 'wysiwyg',
    captionPlural: 'Vortragswerte Krankenkasse',
  },
  zahlungsdateien: { icon: 'payments', captionPlural: 'Zahlungsdateien' },
  svDateifolgenummer: {
    icon: 'youtube_searched_for',
    captionPlural: 'SV - Dateifolgenummern',
  },
};
